#divider {
    margin-top: 1rem;
    margin-bottom: -7.5rem;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.top {
    width: 100vw;
    height: 200px;

    background: var(--color-bg);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 150px), 0 100%);
}

.bottom {
    width: 100vw;
    height: 200px;

    background: var(--color-bg);

    clip-path: polygon(0 150px, 100% 0, 100% 200%, 0 100%);
    margin-top: 200px;
}