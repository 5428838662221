#footer {
    margin-top: 1rem;
}

.footer__container {
    background: var(--color-bg-variant);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0.5rem
}

.footer__socials,
.footer__informations {
    width: 50%;
    padding: 0 5rem;
}

.footer__informations {
    border-left: 3px solid var(--color-white);
}

/* ================ COPYRIGHT ================= */

.footer__copyright {
    width: 100%;
    padding: 0.4rem 0;

    background-color: var(--color-primary);

    text-align: center;
}

.footer__copyright a {
    color: var(--color-bg-variant);
}

.footer__copyright a:hover {
    color: var(--color-bg-variant);
    text-decoration: underline;
}

/* ================ SOCIALS ================= */

.footer__socials {
    text-align: right;
}

.footer__container h3 {
    font-weight: bold;
    font-size: 2rem;
}

.footer__socials__logo {
    display: inline-block;
    vertical-align: middle;
}

.footer__socials__logo img {
    width: 15rem;
}

.footer__socials__icons {
    display: inline-block;
    vertical-align: middle;

    text-align: center;
}

.footer__socials__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    margin: 1rem;

    cursor: pointer;
}

/* ================ INFORMATIONS ================= */

.footer__informations__contact,
.footer__informations__location {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.footer__informations__location {
    margin-top: 2rem;
}

.footer__informations__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 2rem;
}

.footer__informations__content {
    text-align: left;
}

/* ================ ICON STYLE ================= */

.footer_icon {
    width: 60px;
    height: 60px;

    font-size: 2rem;

    background-color: var(--color-primary);
    color: var(--color-bg-variant);

    border-radius: 5rem;

    padding: 0.5rem;
}

.footer_icon svg {
    transition: var(--transition);
}

.footer_icon svg:hover {
    transform: scale(1.3, 1.3);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
    .footer__container {
        flex-direction: column;
    }

    .footer__socials,
    .footer__informations {
        width: 80%;
        text-align: center;
        padding: 0;
    }

    .footer__informations {
        border-top: 3px solid var(--color-white);
        border-left: none;
    }

    .footer__informations__contact,
    .footer__informations__location {
        display: inline-flex;
        width: 100%;
        margin-left: 1rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {

    .footer__socials,
    .footer__informations {
        width: 100%;
    }
}