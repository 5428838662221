#video {
    margin-top: calc(70px + 2rem);
}

.video__container {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
}

.video__informations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}

.video_informations_buttons {
    display: flex;
    gap: 1rem;
}

.video_informations_buttons .mouth__configurator__button,
.video_informations_buttons .contact__button {
    width: auto;
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1rem 0 1rem 0;
    border-radius: 5px;
    font-size: 1rem;
    padding: 0.8rem;
}

.video__title {
    position: relative;
    color: var(--color-primary-variant);
    font-size: 1.4rem;
    padding-left: 1rem;
}

.video__title::before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;

    width: 5px;
    height: 100%;

    border-radius: 3px;

    background: var(--color-primary);
}

.video-react {
    padding: 0 !important;
}

.video-react video {
    width: 100%;
    border: 4px solid var(--color-primary);
}

.video-react-control-bar-auto-hide,
.big-play-button-hide {
    display: none;
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
    .video__container {
        grid-template-columns: 1fr;
    }
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
    .video__title {
        font-size: 1.2rem;
    }
}