@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #ECEAE3;
  --color-bg-variant: #969799;
  --color-primary: #EBBC48;
  --color-primary-variant: #626260;
  --color-white: #FFFFFF;
  --color-light: #E5E5E5;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
  --modal-width: 50%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Libre Franklin', sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
}

/* ============== GENEARL STYLES ============== */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-white);
  transition: var(--transition);
}

a:hover {
  color: var(--color-primary);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

section {
  margin-top: 8rem;
}

/* ============== MODAL ============== */

.modal__overlay {
  position: fixed;
  inset: 0px;
  z-index: 12;
  background-color: rgba(236, 234, 227, 0.85);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-bg-variant);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
  border-radius: 6px;
  width: var(--modal-width);
  padding: 1rem;
}

.modal__icon-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.4rem;
  cursor: pointer;
}

.modal__title {
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-bottom: 2px solid var(--color-primary);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

/* ============== CHECKBOX ============== */

.checkbox-wrapper {
  margin-top: 1rem;
}

.checkbox-wrapper *,
.checkbox-wrapper ::after,
.checkbox-wrapper ::before {
  box-sizing: border-box;
}

.checkbox-wrapper [type=checkbox].substituted {
  margin: 0;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.checkbox-wrapper [type=checkbox].substituted+label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1.15em;
  width: 1.15em;
  margin-top: 0.2rem;
  margin-right: 0.6em;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset, 0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>') no-repeat center, white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
}

.checkbox-wrapper [type=checkbox].substituted:enabled:active+label:before,
.checkbox-wrapper [type=checkbox].substituted:enabled+label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
  background-color: #f0f0f0;
}

.checkbox-wrapper [type=checkbox].substituted:checked+label:before {
  background-color: #3B99FC;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}

.checkbox-wrapper [type=checkbox].substituted:checked:enabled:active+label:before,
.checkbox-wrapper [type=checkbox].substituted:checked:enabled+label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}

.checkbox-wrapper [type=checkbox].substituted:focus+label:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
}

.checkbox-wrapper [type=checkbox].substituted:focus:active+label:before,
.checkbox-wrapper [type=checkbox].substituted:focus+label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
}

.checkbox-wrapper [type=checkbox].substituted:disabled+label:before {
  opacity: 0.5;
}

.checkbox-wrapper [type=checkbox].substituted.dark+label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}

.checkbox-wrapper [type=checkbox].substituted.dark:enabled:active+label:before,
.checkbox-wrapper [type=checkbox].substituted.dark:enabled+label:active:before {
  background-color: #444;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
}

.checkbox-wrapper [type=checkbox].substituted.dark:checked+label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}

.checkbox-wrapper [type=checkbox].substituted.dark:checked:enabled:active+label:before,
.checkbox-wrapper [type=checkbox].substituted.dark:checked:enabled+label:active:before {
  background-color: #c68035;
  color: rgba(0, 0, 0, 0.275);
}

.checkbox-wrapper [type=checkbox].substituted+label {
  -webkit-user-select: none;
  user-select: none;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
}