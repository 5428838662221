#mouth {
	background: var(--color-primary);
	color: var(--color-primary-variant);

	padding: 2rem 0;
	margin-top: 2rem;
}

#mouth canvas {
	cursor: move;
}

#mouth.clickable canvas,
.svg_clickable {
	cursor: pointer;
}

.mouth__container {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 0.7fr 1fr;
	align-items: center;
}

.mouth__configurator__title {
	font-weight: bold;
	font-size: 1.6rem;
	text-transform: uppercase;
	border-bottom: 2px solid var(--color-primary-variant);
	padding-bottom: 0.5rem;
	margin-bottom: 0.5rem;
}

.mouth__configurator__explanations {
	font-size: 0.89rem;
	font-style: italic;
	margin-bottom: 0.5rem;
}

.mouth__configurator__step {
	display: grid;
	grid-template-columns: 0.1fr 1fr;
	gap: 0.2rem;
}

.mouth__configurator__step svg {
	font-size: 2rem;
}

.mouth__configurator__elements {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
	gap: 0.5rem;
	width: 85%;
}

.mouth__configurator__elements__title {
	text-transform: uppercase;
	display: flex;
	justify-content: flex-start;
	margin: 1rem 0 0.5rem 0;
	border-bottom: 1px solid var(--color-primary-variant);
}

.mouth__configurator__elements__title svg {
	border: 1px solid var(--color-primary-variant);
	padding: 0.2rem;
	font-size: 2rem;
	margin-right: 0.5rem;
	border-bottom: 0;
}

.mouth__configurator__element {
	position: relative;
	cursor: pointer;
}

.mouth__configurator__element.selected {
	outline: 1px solid var(--color-bg);
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 5px;
}

.mouth__configurator__element.disabled {
	pointer-events: none;
	border-radius: 5px;
}

.mouth__configurator__element.disabled:before {
	content: '';
	border-radius: 5px;
	position: absolute;
	bottom: 0;
	right: 0;
	opacity: 0.8;
	background-color: var(--color-bg-variant);
	width: 100%;
	height: 100%;
	z-index: 1;
}

.mouth__configurator__element__img {
	border-radius: 5px;
}

.mouth__configurator__element__img_window {
	background-color: var(--color-bg);
	position: absolute;
	height: 70%;
	width: 70%;
	top: 0;
	left: 0;
	transform: translate(22.5%, 22.5%);
	border-radius: 5px;
}

.mouth__configurator__element__label {
	position: absolute;
	bottom: 0;
	background: var(--color-primary-variant);
	color: var(--color-bg);
	width: 100%;
	border-radius: 0 0 5px 5px;
	font-size: 0.7rem;
	padding: 0.2rem;
	text-align: center;
	text-transform: uppercase;
	opacity: 0.8;
}

.mouth__configurator__button {
	cursor: pointer;
	width: 100%;
	border: 1px solid var(--color-primary-variant);
	border-radius: 2px;
	padding: 0.5rem;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	background-color: var(--color-primary-variant);
	color: var(--color-bg);
	font-weight: bold;
	text-transform: uppercase;
	margin: 1rem 0 1rem 0;
}

.mouth__configurator__button:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
	color: var(--color-bg);
}

.mouth__configurator__error {
	color: #DE422F;
	font-size: 0.9rem;
	font-style: italic;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
	.container {
		width: var(--container-width-md);
	}

	.mouth__configurator__element__label {
		font-size: 0.5rem;
	}
}


/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
	.mouth__container {
		grid-template-columns: 1fr;
		gap: 1rem;
	}
}