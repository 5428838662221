.faq__container {
    color: var(--color-primary-variant);
}

.faq__container h2 {
    font-size: 2rem;
}

.accordion-section {
    margin: 1rem 0;

    border: 1px solid var(--color-primary);
}

.accordion-section__title {
    position: relative;
    cursor: pointer;

    padding-right: 40px !important; /* 40px si the width of the icon */
}

.accordion-section__icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    font-size: 2rem;

    color: var(--color-primary);
}

.accordion-section__icon.show {
    opacity: 1;
}

.accordion-section__icon.hide {
    opacity: 0;
}

.accordion-section__title {
    padding: 0.5rem;

    background-color: var(--color-white);
}

.accordion-section__content {
    opacity: 0;
	height: 0;
    padding: 0;

    transition: var(--transition);
    transition-property: opacity, height;
}

.accordion-section.open .accordion-section__content {
    opacity: 1;
	height: 120px;
    padding: 0.5rem;
}

.accordion-section.open .accordion-section__title  {
    background-color: var(--color-primary);
}

.accordion-section.open .accordion-section__icon  {
    color: var(--color-primary-variant);
}