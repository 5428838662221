.aboutus__container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 2rem;

    color: var(--color-primary-variant);
}

.aboutus__content {
    position: relative;
    padding-left: 1rem;

    display: flex;
    justify-content: center;
    flex-direction: column;
}

.aboutus__content > p {
    line-height: 1.2rem;

    padding: 0.5rem 0;
}

.aboutus__content::before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;
    
    width: 5px;
    height: 100%;

    border-radius: 3px;

    background: var(--color-primary);
}

.aboutus__content h2 { 
    color: var(--color-primary);
}

.aboutus__image {
    display: flex;
    align-items: center;
}

/* Rotate */
figure {
	overflow: hidden;

    border: 4px solid var(--color-primary);
}

figure img {
	-webkit-transform: rotate(15deg) scale(1.4);
	transform: rotate(15deg) scale(1.4);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
figure:hover img {
	-webkit-transform: rotate(0) scale(1);
	transform: rotate(0) scale(1);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
    .aboutus__container {
        grid-template-columns: 1fr;
    }

    .aboutus__content {
        padding-left: unset;
    }

    .aboutus__content::before {
        display: none;
    }
}
  