.language-switch {
    transform: translate(0, -50%);
    position: absolute;
    right: 1rem;
    top: 50%;
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 2px;
    padding: 0.2rem;
}

#header.mobile .language-switch {
    position: absolute;
    top: 1rem;
    right: 6rem;
    transform: none;
}