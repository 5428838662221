#header.desktop {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 11;

    background-color: var(--color-bg-variant);
    border-bottom: 4px solid var(--color-primary);
    height: 70px;
    line-height: 70px;

    margin-bottom: 5rem;
}

#header.desktop>div:first-child {
    height: 70px;
}

#header.desktop #links {
    position: absolute;
    width: 70%;
    right: 0;
    top: 0;
    text-align: right;
    padding-right: 130px;
}

#header.desktop #links ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#header.desktop #links ul li {
    display: inline-block;
    margin-left: 40px;
}

#header.desktop #links ul li a {
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--color-white);
    font-weight: bold;
}

#header.desktop #links ul li a:active {
    color: var(--color-primary);
}

#header.desktop #links ul li a:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    top: 100%;
    left: 0;
    background: var(--color-primary);
    transition: transform 0.5s;
    transform: scaleX(0);
    transform-origin: right;
}

#header.desktop #links ul li a:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

#header.desktop #logo {
    width: 30%;
}

#header.desktop #logo img {
    width: 30%;
    margin-left: 6rem;
}

#header.mobile {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 11;

    display: flex;
    justify-content: end;
    align-items: center;

    background-color: var(--color-bg-variant);
    border-bottom: 4px solid var(--color-primary);

    height: 70px;
    width: 100%;
}

#header.mobile>div {
    padding-right: 2rem;
}

#header.mobile>div>svg {
    font-size: 2rem;
    font-weight: bold;
}

#header.mobile #logo {
    position: absolute;
    left: 2rem;
    top: 0;

    width: 20%;
}

#header.mobile .menu_mobile_panel {
    height: 100%;
    width: 100%;

    z-index: 11;

    position: fixed;
    top: 0;
    left: 0;

    background-color: var(--color-bg-variant);

    padding: 1rem;
}

#header.mobile .menu_mobile_panel li {
    padding: 1rem;
}

#header.mobile .menu_mobile_panel li a {
    text-transform: uppercase;
}

#header.mobile .menu_mobile_panel li a:active {
    color: var(--color-primary);
}

#header.mobile .menu_mobile_panel svg {
    font-size: 2.4rem;
    font-weight: bold;

    position: absolute;
    top: 0.6rem;
    right: 1.8rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
    #header.desktop #links {
        width: 80%;
        padding-right: 1rem;
    }

    #header.desktop #logo img {
        width: 20%;
        margin-left: 1rem;
    }
}