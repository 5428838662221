.contact__maps {
    height: 25vh;
    border: 4px solid var(--color-primary);
    border-left: 0;
    border-right: 0;

    margin-bottom: 2rem;
}

.contact__container {
    color: var(--color-primary-variant);
}

.contact__title {
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: uppercase;
}

.contact__title a {
    color: var(--color-primary);
}

.contact__subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-weight: bold;
    font-size: 1rem;
}

.contact__subtitle svg {
    color: var(--color-primary);
    font-size: 3rem;
    margin-right: 1rem;

    transform: scaleX(-1);
}

.contact__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    /* To display button absolute */
    position: relative;
    padding-bottom: 3rem;
}

.contact__form input {
    display: block;
}

.contact__form input,
.contact__form textarea {
    width: 100%;

    border: 1px solid var(--color-primary-variant);
    border-radius: 5px;

    margin: 1rem 0;
    padding: 0.4rem;
}

.contact__form__actions {
    width: 100%;
    text-align: right;
    grid-template-columns: 1fr;
}

.contact__button {
    position: absolute;
    bottom: 0;
    right: 0;

    width: fit-content;
    background-color: var(--color-primary);
    color: var(--color-white);

    border-radius: 5px;

    font-size: 1rem;
    font-weight: bold;

    padding: 0.8rem;

    cursor: pointer;
}

.contact__button:hover {
    background-color: var(--color-white);
    color: var(--color-primary);

    outline: 1px solid var(--color-primary);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
}

.contact__info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    margin-top: 1rem;
    padding-top: 1rem;

    border-top: 2px solid var(--color-primary-variant);
}

.contact__icons {
    display: grid;
    grid-template-columns: 30fr 70fr;

    font-weight: bold;
}

.contact__icons:hover {
    animation: vibrate 0.5s;
}

.contact__icons svg {
    display: flex;
    justify-self: end;
    margin-right: 0.6rem;

    color: var(--color-primary);
    font-size: 3rem;
}

.contact__icons p {
    display: flex;
    align-self: center;

    font-size: 1.4rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
    .contact__info {
        gap: 1rem
    }

    .contact__icons p {
        font-size: 1rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
    .contact__form {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .contact__button {
        width: 100%;
    }

    .contact__info {
        grid-template-columns: 1fr;
    }
}

@keyframes vibrate {
    0% {
        margin-left: 0px;
    }

    25% {
        margin-left: -6px;
    }

    50% {
        margin-left: 0px;
    }

    75% {
        margin-left: 6px;
    }

    100% {
        margin-left: 0px;
    }
}