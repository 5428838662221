#slider {
	margin-top: 2rem;
}

.slider__container {
	display: grid;
	grid-template-columns: 1fr 0.7fr;
}

.slider__informations {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	justify-content: center;

	padding: 2rem;
	color: var(--color-primary-variant);
}

#slider .awesome-slider {
	--slider-height-percentage: 60%;
	--slider-transition-duration: 300ms;
	--organic-arrow-thickness: 6px;
	--organic-arrow-border-radius: 6px;
	--organic-arrow-height: 50px;
	--organic-arrow-color: #000;
	--control-button-width: 10%;
	--control-button-height: 25%;
	--control-button-background: transparent;
	--control-bullet-color: var(--color-primary-variant);
	--control-bullet-active-color: var(--color-primary-variant);
	--loader-bar-color: #851515;
	--loader-bar-height: 6px;
	--content-background-color: transparent;
}

#slider .awesome-slider img {
	border: 4px solid var(--color-primary);
}

.awssld__bullets {
	bottom: 10px !important;
	z-index: 2;
}

.slider__informations_card:hover {
	box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
	#slider .awesome-slider {
		--slider-height-percentage: 60%;
	}
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
	#slider .awesome-slider {
		--slider-height-percentage: 60%;
	}

	.slider__container {
		grid-template-columns: 1fr;
	}
}