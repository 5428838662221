.s0 {
	fill: #e66251
}

.s1 {
	fill: #de422f
}

.s2 {
	fill: #fc8a71
}

.s3 {
	fill: #d43320
}

.s4 {
	fill: #9fdaed
}

.s5 {
	fill: #b3e2f2
}

.s6 {
	fill: #cbedfa
}

.s7 {
	fill: #e3f7fc
}

g.selected .s4,
g.selected .s6,
g.selected .s5 {
	fill: blue;
}