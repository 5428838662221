#features {
    margin-top: 4rem;
}

.features__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;

    margin: 0 2rem;

    color: var(--color-primary-variant);
}

.features__container>div {
    background: var(--color-white);

    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);

    transition: var(--transition);
    padding: 1rem;
}

.features__container>div:hover {
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
}

.features__header {
    width: 100%;
    text-align: center;
    border-bottom: 4px solid var(--color-primary);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.features__header h3 {
    font-weight: bold;
    text-transform: uppercase;
}

.features__details {
    line-height: 1.4rem;
}

.features__details ul li:before {
    content: '- ';

}

.features__details ul li {
    margin-bottom: 1rem;
}


/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
    .features__container {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
    #features {
        margin-top: calc(1rem + 70px);
        /* 70px is the height of the mobile menu */
    }

    .features__container {
        grid-template-columns: 1fr;
    }
}